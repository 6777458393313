// import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism"
// import { MathJax, MathJaxContext } from "better-react-mathjax"
// import { Prism } from "react-syntax-highlighter"
// import ReactMarkdown from "react-markdown"
// import rehypeRaw from "rehype-raw"
// import "github-markdown-css"
// import { memo } from "react"

// function MarkdownViewer({ content }) {
//   const renderers = {
//     code({ children, className, node, ...rest }) {
//       const match = /language-(\w+)/.exec(className || "")
//       return match ? (
//         <Prism
//           {...rest}
//           PreTag="div"
//           children={String(children).replace(/\n$/, "")}
//           language={match[1]}
//           style={oneLight}
//         />
//       ) : (
//         <code {...rest} className={className}>
//           {children}
//         </code>
//       )
//     },
//   }

//   const config = {
//     tex2jax: {
//       inlineMath: [["$", "$"]],
//       displayMath: [["$$", "$$"]]
//     }
//   };

//   // const content1 = `$$\\(12 \\times 12 = 144\\)$$`
//   // const content1 = `\\(12 \\times 12 = 144\\)`

//   return (
//     <div className="markdown-body">
//       <MathJaxContext config={config} version={3}>
//         <MathJax inline>
//           <ReactMarkdown
//             components={renderers}
//             rehypePlugins={[rehypeRaw]}
//           >
//             {content}
//             {/* {content1} */}
//           </ReactMarkdown>
//         </MathJax>
//       </MathJaxContext>

//     </div>
//   )
// }

// export default memo(MarkdownViewer)

import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism"
import { MathJax, MathJaxContext } from "better-react-mathjax"
import { Prism } from "react-syntax-highlighter"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import "github-markdown-css"
import { memo } from "react"

function MarkdownViewer({ content }) {
  const renderers = {
    code({ children, className, node, ...rest }) {
      const match = /language-(\w+)/.exec(className || "")
      return match ? (
        <Prism
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, "")}
          language={match[1]}
          style={oneLight}
        />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      )
    },
  }

  const config = {
    tex: {
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <div className="markdown-body">
      <MathJaxContext config={config}>
        <MathJax dynamic hideUntilTypeset="every">
          <ReactMarkdown
            components={renderers}
            rehypePlugins={[rehypeRaw]}
          >
            {content.replace(/\\\(/g, '$')
              .replace(/\\\)/g, '$')
              .replace(/\\\[/g, '$$')
              .replace(/\\\]/g, '$$')}
          </ReactMarkdown>
        </MathJax>
      </MathJaxContext>

    </div>
  )
}

export default memo(MarkdownViewer)
import { FiMoreHorizontal, FiCheckCircle, FiTrash, FiMenu } from "react-icons/fi"
import { headers, checktoken, hideLadingLoader } from "../../../helper"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { Table, NavDropdown, Badge, Alert } from "react-bootstrap"
import Loader from "../../../components/frontend/TextLoader"
import Toaster from "../../../components/frontend/Toaster"
import { useDispatch, useSelector } from "react-redux"
import { useState, Fragment, useEffect } from "react"
import { Button, IconButton } from "@mui/material"
import Icons from "../../../assets/icons"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"
import { showDialog } from "../../../redux/dialog.slice"

const Brand = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [handleSidebar] = useOutletContext();
	const [brandList, setbrandList] = useState([]);
	const [spinner, setSpinner] = useState(true);
	const chatWords = useSelector(state => state.getChatWords);
	const { user } = useSelector(state => state.authSlice);
	const workspace = useSelector(state => state.getWorkspace)

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (chatWords >= 50000 && user?.currentplan?.packagename === "SOLO") {
				navigate("/pricing")
				return false
			} else if (workspace?.userId?.status === "free" || workspace?.userId?.status === "expired") {
				dispatch(showDialog("ReachLimit"))
				return false
			}
		} else {
			if (user.status === "Solo" && chatWords >= 30000) {
				navigate("/pricing")
				return false
			}
		}
		return true
	}

	const getBrandList = () => {
		setSpinner(true);
		axios.get(`${process.env.REACT_APP_API_URL}/user/brand/list`, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setbrandList(res.data.data);
			}
			if (res.data.success === false) {
				setbrandList(res.data.data);
			}
			setSpinner(false);
		}).catch((err) => { setSpinner(false); checktoken(err) })
	}

	const handleDelete = (id) => {
		axios.delete(`${process.env.REACT_APP_API_URL}/user/barnd/delete/${id}`, {
			withCredentials: true,
			credentials: "include",
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
			}
			getBrandList();
		}).catch((err) => {
			checktoken(err);
			if (err.response.data.success === false) {
				let errors = err.response.data.error;
				toast.error(errors);
			}
		})
	}

	const handleSetDefault = (id) => {
		axios.put(`${process.env.REACT_APP_API_URL}/user/default/barnd/${id}`, {
			withCredentials: true,
			credentials: "include",
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				toast.success(res.data.message);
			}
			getBrandList();
		}).catch((err) => {
			checktoken(err);
			if (err.response.data.success === false) {
				let errors = err.response.data.error;
				toast.error(errors);
			}
		})
	}

	useEffect(() => {
		hideLadingLoader();
		checkPlan();
		getBrandList();

		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>Brand Voice</title>
				<meta property="og:title" content={"Brand Voice"} />
			</Helmet>
			{spinner === true ? <Loader /> : ""}
			<Toaster />
			<div className="user-dashboard-header d-block d-xl-flex border-bottom justify-content-between">
				<div className="d-flex d-xl-none mb-3 justify-between items-center">
					<IconButton
						size="small"
						onClick={handleSidebar}
						className="sidebar-menu-btn"
						sx={{ display: { laptop: "none" } }}
					><Icons.FiMenu />
					</IconButton>
					<Button
						size="small"
						variant="contained"
						LinkComponent={Link}
						to={`${window.location.origin}/user/brand-new`}
						startIcon={<Icons.FiPlus className="!text-sm" />}
						className="fill-btn clr-primary small-btn !capitalize"
					>new brand voice
					</Button>
				</div>
				<div className="d-block">
					<h5 className="m-0 mb-2 page-header">brand voice</h5>
					<p className="m-0">Create different Brand Voices to use in various instances - ensuring consistency of your AI-generated content.</p>
				</div>
			</div>

			<div className="brand-page">
				<div className="d-none d-xl-block mb-3 justify-content-between">
					<button className="d-inline-block text-center text-decoration-none page-header-btn-dark btn menu-btn me-2" onClick={handleSidebar}><FiMenu /></button>
					<Button
						size="small"
						variant="contained"
						LinkComponent={Link}
						startIcon={<Icons.FiPlus className="!text-sm" />}
						className="fill-btn clr-primary small-btn !capitalize"
						to={`${window.location.origin}/user/brand-new`}
					>new brand voice
					</Button>
				</div>
				{
					brandList.length > 0 &&
					<Table striped hover bordered className="brand-table">
						<thead>
							<tr>
								<th>N°</th>
								<th>Name</th>
								<th>brand voice</th>
								<th>actions</th>
							</tr>
						</thead>
						<tbody>
							{
								brandList && brandList.map((item, index) => {
									const number = index + 1;
									return (
										<tr key={index}>
											<td>{number}</td>
											<td>
												<Link to={`/user/brand-update/${item?._id}`} className="link-color-remove">
													{item?.name}
													{
														item?.is_check === "default" ?
															<Badge bg="primary text-capitalize ms-2">default voice</Badge> : ""
													}
												</Link>
											</td>
											<td className="w-3/5 truncate p-3 link-color-remove">
												{(item?.content?.length > 85) ?
													`${item?.content?.substring(0, 85)}...`
													: item.content}
											</td>
											<td>
												<NavDropdown title={<FiMoreHorizontal />} id="basic-nav-dropdown2">
													{
														item?.is_check !== "default" &&
														<Link className="dropdown-item" onClick={() => handleSetDefault(item?._id)}>
															<FiCheckCircle className="me-2" />
															set as default voice
														</Link>
													}
													<Link className="dropdown-item" onClick={() => handleDelete(item?._id)}>
														<FiTrash className="me-2" />
														delete brand voice
													</Link>
												</NavDropdown>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</Table>
				}
				{brandList.length === 0 &&
					<Alert variant="warning">No Brand voice added yet</Alert>
				}
			</div>
		</Fragment>
	)

}

export default Brand;
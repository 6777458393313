import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { BASEURL, headers } from "../helper"

export const generateHtmlFromText = createAsyncThunk("generateHtmlFromText", (text, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.post(`${BASEURL}/generate-html`, { text }, {
      headers: headers()
    }).then((res) => {
      if (res?.data?.success) {
        const pdfUrl = res?.data?.pdfFile
        dispatch(downloadPdf(pdfUrl))
        return res?.data
      }
    }).catch((err) => {
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

export const deletePdfFromLocal = createAsyncThunk("deletePdfFromLocal", (url, { rejectWithValue }) => {
  try {
    axios.post(`${BASEURL}/remove-pdf`, { url }, {
      headers: headers()
    }).then((res) => {
    }).catch((err) => {
      return rejectWithValue(err)
    })
  } catch (error) {
    console.log(error)
  }
})

export const downloadPdf = createAsyncThunk("generateHtmlFromText", async (fileUrl, { dispatch }) => {
  try {
    const response = await axios({
      url: `${BASEURL}/download-pdf`,
      method: "POST",
      data: { fileUrl },
      responseType: "blob"
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))

    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "document.pdf")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setTimeout(() => {
      dispatch(deletePdfFromLocal(fileUrl))
    }, 10000)
  } catch (error) {
    console.error("Error downloading the PDF", error)
  }
})



const initialState = {
  loading: false
}
const pdfGenerate = createSlice({
  name: "pdfGenerate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Generating PDF
    builder.addCase(generateHtmlFromText?.pending, (state) => {
      state.loading = true
    })
    builder.addCase(generateHtmlFromText?.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(generateHtmlFromText?.rejected, (state) => {
      state.loading = false
    })

    // Logout
    builder.addCase("LOGOUT", () => initialState)
  }
})

export default pdfGenerate.reducer
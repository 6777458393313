import { Button, CircularProgress, IconButton, Stack, styled } from "@mui/material"
import { generateHtmlFromText } from "../../redux/pdfGenerate.slice"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { setEditorText } from "../../redux/other.slice"
import { useDispatch, useSelector } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import htmlDocx from "html-docx-js/dist/html-docx"
import { UPLOAD_CKEDITOR_URL } from "../../helper"
import Icons from "../../assets/icons"
import { saveAs } from "file-saver"
import { Fragment } from "react"

const Div = styled("div")(() => ({}))

function Drawer({ show, setDrawer }) {
  const dispatch = useDispatch()
  const { editorText } = useSelector(state => state.otherSlice)
  const { loading } = useSelector(state => state.pdfGenerateSlice)

  const handleExportPdf = () => {
    dispatch(generateHtmlFromText(editorText))
  }

  const handleExportDoc = () => {
    const html = document.querySelector(".ck-editor__editable").innerHTML
    const newText = `<html><head><style>
    table { border-collapse: collapse,;width:100%;} 
    th, td { border: 0.5px solid #b3b3b3; padding: 8px; text-align: left; }
    img { width: 100%; max-width: 100%; height: auto; display: block; object-fit: contain;}
    </style></head><body>${html}
    </body></html>`
    const converted = htmlDocx.asBlob(newText);
    const reader = new FileReader();
    reader.onload = () => {
      saveAs(new Blob([reader.result]), "document.docx");
    };
    reader.readAsArrayBuffer(converted, "UTF-8");
  }

  return (
    <Fragment>
      {show &&
        <Div
          sx={{
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            zIndex: 999,
            position: "fixed",
            bgcolor: "#0000005c",
          }}
          onClick={() => { setDrawer(false) }}
        />
      }
      <Div
        sx={{
          p: 0.5,
          top: "0",
          left: "100%",
          bottom: "0",
          zIndex: 9999,
          display: "flex",
          direction: "rtl",
          bgcolor: "white",
          overflow: "auto",
          resize: "horizontal",
          position: "absolute",
          flexDirection: "column",
          transition: "transform ease 350ms",
          transform: show ? "translateX(-100%)" : "translateX(0%)",
          width: { xs: "calc(100% - 30px)", sm: "450px", laptop: "50%" },
        }}
      >
        <Div
          sx={{
            p: 1,
            display: "flex",
            direction: "ltr",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" spacing={1}>
            {loading ?
              <Button
                size="small"
                variant="contained"
                onClick={handleExportPdf}
                className="fill-btn clr-dark"
                startIcon={<CircularProgress
                  size={20}
                  thickness={5}
                  sx={{ color: "white", display: "flex" }}
                />}
              >Generating
              </Button> :
              <Button
                size="small"
                variant="contained"
                onClick={handleExportPdf}
                className="fill-btn" style={{ background: '#000000' }}
                startIcon={<Icons.BsFillFileEarmarkPdfFill style={{ fontSize: "18px" }} />}
              >Export as PDF
              </Button>
            }
            <Button
              size="small"
              variant="contained"
              onClick={handleExportDoc}
              className="fill-btn clr-primary"
              startIcon={<Icons.DOC className="fs-5" />}
            >Export as DOC
            </Button>
          </Stack>
          <Div>
            <IconButton
              sx={{ ":hover": { color: "#111827" } }}
              onClick={() => { setDrawer(false) }}
            ><Icons.Cross className="fs-5" />
            </IconButton>
          </Div>
        </Div>
        <Div
          sx={{ p: 1, flexGrow: 1, overflow: "auto" }}
          className="cus-drawer-body"
        >
          <CKEditor
            editor={ClassicEditor}
            data={editorText}
            onReady={editor => {
              editor.editing.view.change(writer => {
                writer.setStyle("min-height", "calc(100vh - 70px)", editor.editing.view.document.getRoot())
              })
            }}
            onChange={(_, editor) => {
              if (editor.getData()) {
                dispatch(setEditorText(editor.getData()))
              }
            }}
            config={{
              ckfinder: {
                uploadUrl: UPLOAD_CKEDITOR_URL
              },
              toolbar: [
                "undo", "redo", "|", "heading", "|", "bold", "italic", "insertTable", "blockQuote", "|", "link", "uploadImage", "bulletedList", "numberedList",
              ],
              mediaEmbed: {
                previewsInData: true,
                elementName: "iframe",
              },
              image: {
                resizeUnit: "px",
                resizeOptions: [{
                  name: "resizeImage:original",
                  value: null,
                  label: "Original size"
                },
                {
                  name: "resizeImage:50",
                  value: "50",
                  label: "Medium size"
                },
                {
                  name: "resizeImage:75",
                  value: "75",
                  label: "Large size"
                }]
              },
            }}
          />
        </Div>
      </Div>
    </Fragment >
  )
}

export default Drawer
import { headers, checktoken, hideLadingLoader } from "../../../helper"
import { useNavigate, Link, useOutletContext } from "react-router-dom"
import { Col, Form, Card, Button, Spinner } from "react-bootstrap"
import { useState, Fragment, useEffect, useMemo } from "react"
import Loader from "../../../components/frontend/TextLoader"
import { getTotalWordsReq } from "../../../redux/chat.slice"
import Toaster from "../../../components/frontend/Toaster"
import { showDialog } from "../../../redux/dialog.slice"
import { useDispatch, useSelector } from "react-redux"
import { setChatWords } from "../../../actions/index"
import { Button as MuiButton } from "@mui/material"
import Icons from "../../../assets/icons"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import axios from "axios"

const BrandNew = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [handleSidebar] = useOutletContext()
	const [spinner, setSpinner] = useState(true)
	const [oldValue, setOldValue] = useState("")
	const [buttonSpn, setButtonSpn] = useState(false)
	const { user } = useSelector(state => state.authSlice)
	const workspace = useSelector(state => state.getWorkspace)
	const [brand, setBrand] = useState({ name: "", content: "" })
	const { totalWords: chatWords } = useSelector(state => state.chatSlice)
	const handleChange = (e) => {
		let value = e.target.value
		let name = e.target.name
		setBrand({
			...brand,
			[name]: value
		})
	}

	// generate Brand  barnd voice ------------------------------------------
	const brandGenerator = (e) => {
		e.preventDefault();
		const user = JSON.parse(localStorage.getItem("authuser"));
		if (chatWords >= 20000 && user.plan === "Standard") {
			navigate("/pricing");
			return false;
		}
		if (brand.content === "") {
			toast.error("Please fill the brand input!");
			return false;
		}
		setOldValue(brand.content);
		setButtonSpn(true);
		axios.post(`${process.env.REACT_APP_API_URL}/user/generate/brand/voice`, brand, {
			headers: headers()
		}).then((res) => {
			setButtonSpn(false);
			if (res.data.success === true) {
				setBrand(res.data.data);
			}
		}).catch((err) => {
			checktoken(err);
			setButtonSpn(false);
			if (err.response.data.success === false) {
				toast.error(err.response.data.error);
			}
		});
		dispatch(getTotalWordsReq(user))
	}

	// create  barnd voice ------------------------------------------
	const brandSubmit = (e) => {
		e.preventDefault();
		axios.post(`${process.env.REACT_APP_API_URL}/user/brand/create`, brand, {
			headers: headers()
		}).then((res) => {
			if (res.data.success === true) {
				setBrand("");
				toast.success(res.data.message);
				navigate("/user/brand");
			}
		}).catch((err) => {
			checktoken(err);
			if (err.response.data.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	// reset Brand  barnd voice ------------------------------------------
	const reSetBrand = (e) => {
		e.preventDefault();
		const content = {
			content: oldValue
		}
		setBrand(content);
	}

	const checkPlan = () => {
		if (user?.appsumocode === null) {
			if (chatWords >= 50000 && user?.currentplan?.packagename === "SOLO") {
				navigate("/pricing");
				return false
			} else if (workspace?.userId?.status === "free" || workspace?.userId?.status === "expired") {
				dispatch(showDialog("ReachLimit"))
				return false
			}
		} else {
			if (user?.plan === "Solo" && chatWords >= 30000) {
				navigate("/pricing");
				return false
			}
		}
	}

	useMemo(() => {
		chatWords && localStorage.setItem("totalWords", chatWords);
		chatWords && dispatch(setChatWords(chatWords))
		return true;

		// eslint-disable-next-line
	}, [chatWords])

	useEffect(() => {
		checkPlan();
		hideLadingLoader();
		setTimeout(() => {
			setSpinner(false);
		}, 100);

		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>Brand Voice</title>
				<meta property="og:title" content={"Brand Voice"} />
			</Helmet>
			{spinner === true ? <Loader /> : ""}
			<Toaster />
			<div className="user-dashboard-header border-bottom align-items-sm-center">
				<div className="d-flex align-content-center justify-content-between">
					<button className="d-inline-block d-xl-none text-center text-decoration-none page-header-btn-dark btn menu-btn me-2" onClick={handleSidebar}><Icons.FiMenu /></button>
					<Link to="/user/brand">
						<Button variant="light" size="sm" className="btn btn-light page-header-btn w-auto" ><Icons.FiArrowLeft className="mx-2" /> Go Back </Button>
					</Link>
				</div>
				<h5 className="m-0 mt-2 mb-2 page-header">add  brand voice</h5>
				<p className="m-0">Write or paste content that reflects your brand voice. For best results, we recommend between 50-500 words.</p>

			</div>
			<div className="d-block brand-form">
				<Col xxl={6} xl={6} lg={6} md={6} sm={12}>
					{brand?.role === "assistant" ?
						<Fragment>
							<Form onSubmit={brandSubmit}>
								<Form.Group>
									<Form.Label>NAME OF THE BRAND VOICE (REQUIRED)</Form.Label>
									<Form.Control type="text" placeholder="ex: Alta Marketing" name="name" value={brand?.name} onChange={handleChange} />
								</Form.Group>
								<Form.Group>
									<Form.Label>BRAND VOICE</Form.Label>
									<Form.Control name="content" value={brand?.content} onChange={handleChange}
										as="textarea" rows={10} />
									<Card className="my-4 bg-light">
										<Card.Body>
											<Card.Title> <Icons.FiSunrise /> TIPS FOR EDITING A BRAND VOICE</Card.Title>
											<Card.Text>
												1. When editing, make sure not to use negative commands. (Ex: Use "Be courteous.", instead of "Don't be rude.") <br />
												2. For best results, you can redo the analysis instead of editing.
											</Card.Text>

										</Card.Body>
									</Card>
								</Form.Group>
								<Form.Group>
									<div className="py-2">
										<Form.Check // prettier-ignore
											type="checkbox"
											id={`default-checkbox`}
											label={`SAVE AS THE DEFAULT VOICE ACROSS YOUR WORKSPACE`}
											name="isCheck"
											value={`default`}
											onChange={handleChange}
										/>

									</div>
								</Form.Group>
								<Form.Group className="mt-3">
									<MuiButton
										type="submit"
										disableRipple
										variant="contained"
										className="fill-btn my-4 clr-primary !font-ff-lato !capitalize me-2 !rounded-[6px]"
									>Save Brand Voice
									</MuiButton>
									<Button variant="light" onClick={reSetBrand}>Redo Analysis</Button>
								</Form.Group>
							</Form>
						</Fragment> :
						<Fragment>
							<Form onSubmit={brandGenerator}>
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Form.Label>content to analyze</Form.Label>
									<Form.Control name="content" value={brand?.content} onChange={(e) => { setBrand(e.target.value); handleChange(e); }}
										as="textarea" placeholder="Add a blog article, social media posts, company mission, website copy, marketing emails, or any content that matches your desired brand voice. For best results, we recommend at least 300 words." rows={10} />
									<Form.Text className="text-end d-block" id="passwordHelpBlock" muted>
										<span>{brand.content.split(" ").length - 1}</span> Words
									</Form.Text>
								</Form.Group>
								<Form.Group>
									<button className={`page-header-btn d-flex btn align-items-center text-decoration-none page-header-btn-light w-auto px-3 ${brand.content === "" && "disabled"}`} > analyze brand voice {buttonSpn === true && <Spinner size="sm" className="ms-2" />}<Icons.FiArrowRight className="ms-2" /></button>
								</Form.Group>
							</Form>
						</Fragment>
					}
				</Col>
			</div>
		</Fragment>
	)
}

export default BrandNew;